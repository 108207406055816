.header {
  background: rgb(235, 80, 0);
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title {
  color: rgb(255, 255, 255);
  font-family: RF Dewi Extended;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
}

.logo {
  height: 70%;
  position: absolute;
  top: 15%;
  left: 5%;
}

@media screen and (max-height: 600px) {
  .header {
    height: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 60px;
  }
  .title {
    font-size: 16px;
  }
}
