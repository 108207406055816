.button {
  border: 1px solid rgb(201, 209, 67);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  padding: 16px;
  color: rgb(0, 0, 0);
  font-family: RF Dewi Extended;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.buttonActive {
  border: 1px solid rgb(201, 209, 67);
  border-radius: 4px;
  background: rgb(201, 209, 67);
  padding: 16px;
  color: rgb(0, 0, 0);
  font-family: RF Dewi Extended;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  /* letter-spacing: 1px; */
  text-align: left;
}

@media screen and (max-height: 600px) {
  .button {
    padding: 6px;
    font-size: 8px;
    line-height: 8px;
  }
  .buttonActive {
    padding: 8px;
    font-size: 10px;
    line-height: 12px;
  }
}

@media screen and (max-width: 600px) {
  .button {
    font-size: 10px;
    line-height: 20px;
    padding: 12px;
  }

  .buttonActive {
    font-size: 10px;
    line-height: 20px;
    padding: 12px;
  }
}

@media (hover: hover) {
  .button:hover {
    cursor: pointer;
    background: rgb(201, 209, 67);
  }
}

@media (hover: none) {
  .button:active {
    background: rgb(201, 209, 67);
  }
}
