.messageManager {
  border-radius: 4px;
  background: rgb(246, 246, 246);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 4px;
  position: relative;
}

.messageClient {
  border-radius: 4px;
  background: rgb(201, 209, 67);
  padding: 24px;
  display: flex;
  align-self: flex-end;
  width: 80%;
  margin-bottom: 4px;
  position: relative;
}

.text {
  color: rgb(0, 0, 0);
  font-family: RF Dewi Extended;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin: 0;
  max-width: 100%;
}

.logoManager {
  position: absolute;
  left: -50px;
  bottom: 5px;
  width: 40px;
  height: 40px;
}

.logoClient {
  position: absolute;
  right: -50px;
  bottom: 5px;
  width: 40px;
  height: 40px;
}

@media screen and (max-height: 600px) {
  .messageManager {
    padding: 12px;
  }
  .messageClient {
    padding: 12px;
  }
  .text {
    font-size: 10px;
    line-height: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .logoManager {
    left: -40px;
    width: 35px;
    height: 35px;
  }
  .logoClient {
    right: -40px;
    width: 35px;
    height: 35px;
  }

  .messageClient {
    padding: 20px;
  }
  .messageManager {
    padding: 20px;
  }

  .text {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 600px) {
  .logoManager {
    left: -40px;

    width: 26px;
    height: 26px;
  }
  .logoClient {
    right: -40px;
    width: 26px;
    height: 26px;
  }

  .messageClient {
    align-self: flex-start;
    padding: 12px;
  }
  .messageManager {
    padding: 12px;
  }

  .text {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }
}
