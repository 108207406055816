.chat {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding-bottom: 40px;
  height: 100%;
  max-width: 924px;
  margin: 40px auto;

  background-image: url(../../images/messages-back.svg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: flex-end;
}

@media screen and (max-height: 600px) {
  .chat {
    margin: 10px auto;
  }
}

@media screen and (max-width: 1023px) {
  .chat {
    max-width: 688px;
  }
}

@media screen and (max-width: 600px) {
  .chat {
    max-width: 343px;
    margin-top: 20px;
  }
}
