.messages {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  padding: 0 84px 0px 84px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  overflow-x: hidden;
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
@media screen and (max-width: 600px) {
  .messages {
    padding: 40px 12px 0px 50px;
  }
}
