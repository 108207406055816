.header {
  background: rgb(143, 23, 129);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 20px;
}

.link {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(255, 255, 255);
  font-family: RF Dewi Extended;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-decoration: none;
}

@media screen and (max-height: 600px) {
  .header {
    padding: 6px 20px;
    height: 20px;
  }

  .link {
    font-size: 12px;
  }

  .img {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    height: 76px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 40px;
    padding: 12px;
  }

  .link {
    font-size: 16px;
    line-height: 19px;
  }
}
