@font-face {
  font-family: 'RF Dewi Extended';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/RFDewiExtended-Bold.woff2') format('woff2'),
    url('./fonts/RFDewiExtended-Bold.woff2') format('woff');
}

@font-face {
  font-family: 'RF Dewi Extended';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/RFDewiExpanded-Regular.woff2') format('woff2'),
    url('./fonts/RFDewiExpanded-Regular.woff') format('woff');
}
