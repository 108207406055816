.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 80%;
  padding: 12px 84px 0 84px;
}

@media screen and (max-width: 600px) {
  .buttons {
    padding: 12px 12px 0 12px;
    max-width: 100%;
  }
}
